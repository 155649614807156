export interface TaxCalculationInputs {
    salary: number;
    isMonthly: boolean;
    hasFamilyExemption: boolean;
    calculateSocialSecurity: boolean;
    isVoluntary: boolean;
  }
  
  export interface TaxCalculationResult {
    monthlyTax: number;
    yearlyTax: number;
    netMonthlySalary: number;
    socialSecurityDeduction: number;
  }
  
  const INDIVIDUAL_EXEMPTION = 9000;
  const FAMILY_EXEMPTION = 18000;
  const SOCIAL_SECURITY_RATE = 0.075;
  const VOLUNTARY_SOCIAL_SECURITY_RATE = 0.175;
  
  export function calculateTax(inputs: TaxCalculationInputs): TaxCalculationResult {
    const { salary, isMonthly, hasFamilyExemption, calculateSocialSecurity, isVoluntary } = inputs;
    
    const annualSalary = isMonthly ? salary * 12 : salary;
    const monthlySalary = annualSalary / 12;
    const exemption = hasFamilyExemption ? FAMILY_EXEMPTION : INDIVIDUAL_EXEMPTION;
    const taxableIncome = annualSalary - exemption;
  
    let yearlyTax = 0;
    if (taxableIncome > 0) {
      if (taxableIncome <= 5000) {
        yearlyTax = taxableIncome * 0.05;
      } else if (taxableIncome <= 10000) {
        yearlyTax = 5000 * 0.05 + (taxableIncome - 5000) * 0.10;
      } else if (taxableIncome <= 15000) {
        yearlyTax = 5000 * 0.05 + 5000 * 0.10 + (taxableIncome - 10000) * 0.15;
      } else if (taxableIncome <= 20000) {
        yearlyTax = 5000 * 0.05 + 5000 * 0.10 + 5000 * 0.15 + (taxableIncome - 15000) * 0.20;
      } else if (taxableIncome <= 1000000) {
        yearlyTax = 5000 * 0.05 + 5000 * 0.10 + 5000 * 0.15 + 5000 * 0.20 + (taxableIncome - 20000) * 0.25;
      } else {
        yearlyTax = 5000 * 0.05 + 5000 * 0.10 + 5000 * 0.15 + 5000 * 0.20 + (980000) * 0.25 + (taxableIncome - 1000000) * 0.30;
      }
    }
  
    const monthlyTax = yearlyTax / 12;
    const socialSecurityDeduction = calculateSocialSecurity ? monthlySalary * (isVoluntary ?  VOLUNTARY_SOCIAL_SECURITY_RATE: SOCIAL_SECURITY_RATE) : 0;
    const netMonthlySalary = monthlySalary - monthlyTax - socialSecurityDeduction;
  
    return {
      monthlyTax,
      yearlyTax,
      netMonthlySalary,
      socialSecurityDeduction
    };
  }
  