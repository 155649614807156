import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto'; // Importing necessary chart.js modules
import { t } from 'i18next';
import CopyToClipboard from '../util/copyToClipboard';

interface CalculationResultProps {
  monthlyTax: number;
  yearlyTax: number;
  netMonthlySalary: number;
  socialSecurityDeduction: number;
}

const StyledCalculationResult: React.FC<CalculationResultProps> = ({ monthlyTax, yearlyTax, netMonthlySalary, socialSecurityDeduction }) => {
  const data = {
    labels: [t('monthlyTax'), t('socialSecurityDeduction'), t('netMonthlySalary')],
    datasets: [
      {
        data: [monthlyTax, socialSecurityDeduction, netMonthlySalary],
        backgroundColor: ['#7c64c3', '#7ED321', '#4898ff'], // Custom colors for the chart sections
        hoverBackgroundColor: ['#357ABD', '#66B032', '#9C00D0'], // Hover effect colors
      },
    ],
  };

  return (
    <Card variant="outlined" style={{ marginTop: '0px', padding: '0px 4px', backgroundColor: '#fefefe' }}>
      <CardContent style={{padding: '8px'}}>
        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {t('Monthly Payment Breakdown')}
        </Typography>

        <Box display="flex" justifyContent="center" margin="0 auto" marginTop="20px" width="70%" height="200px">
          <Doughnut 
            data={data} 
            options={{ 
              cutout: '70%', // Makes the doughnut thinner
              responsive: true,
              plugins: {
                legend: {
                  display: false 
                }
              }
            }} 
          />
        </Box>

        <Grid container spacing={2} marginTop="8px">
           <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <AttachMoneyIcon style={{ color: '#4898ff', marginRight: '10px' }} />
              <Typography variant="h6">{t('netMonthlySalary')}</Typography>
            </Box>
            <Typography variant="h4" textAlign="center" style={{ color: '#4898ff' }}>
                {netMonthlySalary.toFixed(2)} 
                <small>{t('JOD')}</small>
                <CopyToClipboard text={netMonthlySalary.toFixed(2)} />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <AttachMoneyIcon style={{ color: '#7c64c3', marginRight: '10px' }} />
              <Typography variant="h6">{t('monthlyTax')}</Typography>
            </Box>
            <Typography textAlign="center" variant="h4" style={{ color: '#7c64c3' }}>
                {monthlyTax.toFixed(2)}
                <small>{t('JOD')}</small>
                <CopyToClipboard text={monthlyTax.toFixed(2)} />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <SecurityIcon style={{ color: '#7ED321', marginRight: '10px' }} />
              <Typography variant="h6">{t('socialSecurityDeductionLabel')}</Typography>
            </Box>
            <Typography textAlign="center" variant="h4" style={{ color: '#7ED321' }}>
                {socialSecurityDeduction.toFixed(2)}
                <small>{t('JOD')}</small>
                <CopyToClipboard text={socialSecurityDeduction.toFixed(2)} />
            </Typography>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
    };

export default StyledCalculationResult;
