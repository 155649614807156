import { Divider } from "@mui/material";
import TaxForm from "./TaxForm";
import Info from "./Info";

const Home: React.FC = () => {
    return (
        <>
            <TaxForm/>
            <Divider />
            <Info />
        </>
    );
}

export default Home;