import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const Header: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const StyledAppBar = styled(AppBar)({
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #d3d3d3',
    color: '#000000',
  });

  const Logo = styled('img')({
    maxWidth: '7em',
    marginRight: '1em',
  });
  

  return (
    <StyledAppBar position="static">
      <Toolbar>
          
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Logo src={`/jordan_tax_calculator_logo_${i18n.language}.svg`} />
        </Typography>
        <Box>
          <Button color="inherit" onClick={() => changeLanguage('en')}>E</Button>
          <Button color="inherit" onClick={() => changeLanguage('ar')}>ع</Button>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
