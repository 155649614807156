import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { t } from 'i18next';
import React, {  } from 'react';
import { TaxCalculationInputs, TaxCalculationResult } from '../util/calculator';


interface SavedCalculationProps {
    input: TaxCalculationInputs;
    result: TaxCalculationResult;
    index: number;
}

const CalculationCard: React.FC<SavedCalculationProps> = ({input, result, index}) => {
    return (
        <Accordion key={index}>
        <AccordionSummary>
          <Typography>{input.salary} {input.isMonthly ? t('Monthly') : t('Yearly')}
            ({input.hasFamilyExemption ? t('Family Exemption') : t('Individual Exemption')})
            ({input.calculateSocialSecurity ? (input.isVoluntary ? t('voluntarySubscriptionLabel') : t('regularSubscriptionLabel')) : t('withoutSocialSecurity')})
           </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t('monthlyTax')}: {result.monthlyTax.toFixed(2)}</Typography>
          <Typography>{t('yearlyTax')}: {result.yearlyTax.toFixed(2)}</Typography>
          <Typography>{t('netMonthlySalary')}: {result.netMonthlySalary.toFixed(2)}</Typography>
        </AccordionDetails>
      </Accordion>
    )

}

export default CalculationCard;