import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Info = () => {
  const { i18n } = useTranslation();

  const descriptions: any = {
    en: {
      title: "How is Tax Calculated?",
      content: (
        <div>
          Income tax in Jordan is based on your total annual earnings after applying eligible exemptions. The key exemptions are:
          <ul>
            <li><strong>Individual Exemption</strong>: JOD 9,000</li>
            <li><strong>Family Exemption</strong>: JOD 18,000 (for married individuals with dependents)</li>
          </ul>
          After applying these exemptions, your taxable income is divided into brackets, each taxed at a specific rate. Additionally, Social Security deductions are typically 7.5%, with different rates for voluntary contributions.
          <br /><br />
          For a detailed guide on how tax is calculated, <Link to="/tax-rules-blog">click here to read more</Link>.
        </div>
      ),
    },
    ar: {
      title: "كيف يتم حساب الضريبة؟",
      content: (
        <>
          تُحسب ضريبة الدخل في الأردن بناءً على إجمالي دخلك السنوي بعد تطبيق الإعفاءات المستحقة. الإعفاءات الرئيسية هي:
          <ul>
            <li><strong>الإعفاء الشخصي</strong>: 9,000 دينار أردني</li>
            <li><strong>الإعفاء العائلي</strong>: 18,000 دينار أردني (للمتزوجين مع معالين)</li>
          </ul>
          بعد تطبيق هذه الإعفاءات، يتم تقسيم دخلك الخاضع للضريبة إلى شرائح، حيث تخضع كل شريحة لمعدل ضريبي معين. كما يتم خصم الضمان الاجتماعي بنسبة 7.5% عادةً، مع نسب مختلفة للاشتراكات الطوعية.
          <br /><br />
          لمعرفة المزيد حول كيفية حساب الضريبة، <Link to="/tax-rules-blog">اضغط هنا لقراءة المزيد</Link>.
        </>
      ),
    },
  };

  const language = i18n.language;

  return (
    <Box padding={2} textAlign={language === 'ar' ? 'right' : 'left'}>
      <Typography variant="h5" gutterBottom>
        {descriptions[language].title}
      </Typography>
      <Typography variant="body1" component="article">
        {descriptions[language].content}
      </Typography>
    </Box>
  );
};

export default Info;
