import React, { ChangeEvent, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, Button, Switch, OutlinedInput, InputAdornment, Typography, Stack, Grid, Box, Tooltip, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { calculateTax, TaxCalculationInputs, TaxCalculationResult } from '../util/calculator';
import CalculationCard from './CalculationCard';
import { convertHindiToArabic } from '../util/functions';
import CalculationResult from './CalculationResult';
import { HelpOutline } from '@mui/icons-material';

const TaxForm: React.FC = () => {
  const { t } = useTranslation();
  const [salary, setSalary] = useState<number>(1000);
  const [salaryError, setSalaryError] = useState<string | null>();
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const [hasFamilyExemption, setHasFamilyExemption] = useState<boolean>(false);
  const [calculateSocialSecurity, setCalculateSocialSecurity] = useState<boolean>(true);
  const [isVoluntary, setIsVoluntary] = useState<boolean>(false);  // State for voluntary subscription
  const [result, setResult] = useState<TaxCalculationResult | null>(null);
  const [savedCalculations, setSavedCalculations] = useState<SavedCalculation[]>([]);


interface SavedCalculation {
    input: TaxCalculationInputs;
    result: TaxCalculationResult;
}

 const handleSubmit = () => {
    const inputs: TaxCalculationInputs = {
      salary,
      isMonthly,
      hasFamilyExemption,
      calculateSocialSecurity,
      isVoluntary
    };
    const taxResult = calculateTax(inputs);
    setResult(taxResult);
    setSavedCalculations(prev => [  { input: inputs, result: taxResult }, ...prev]);
  };

  const handleMonthlyYearlyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isMonthlyValue = e.target.value === 'monthly';
    console.debug({isMonthlyValue});
    setIsMonthly(isMonthlyValue);
    setSalary(isMonthlyValue ? salary / 12 : salary * 12);
  };

  const handleSalaryChange = (val: any) => {
    val = convertHindiToArabic(val);
    setSalary(val);
    const value = parseFloat(val);
    if (!isNaN(Number(value))) {
        setSalary(value);
        setSalaryError(null);
      } else {
        setSalaryError(t('validNumberError'));
      }
  };

  return (
    <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid container item md={12} xl={12}>
        <Stack width='100%' spacing={1}>
        <FormControl component="fieldset">
            <RadioGroup row value={isMonthly ? 'monthly' : 'yearly'} onChange={(e) => handleMonthlyYearlyChange(e) }>
            <FormControlLabel value="monthly" control={<Radio />} label={t('monthlySalary')} />
            <FormControlLabel value="yearly" control={<Radio />} label={t('yearlySalary')} />
            </RadioGroup>
        </FormControl>

        <OutlinedInput 
            value={salary}
            dir='ltr'
            error={!!salaryError}
            onChange={(e) => handleSalaryChange(e.target.value)}
            inputMode='numeric'
            style={{fontSize: '1.5rem'}}
            startAdornment={<InputAdornment position="start"> {t('JOD')} </InputAdornment>}
            endAdornment={<InputAdornment position="end">{!isMonthly ? ((salary / 12) + ' x ' + '12') : ''}</InputAdornment>}
            fullWidth
        />
        {salaryError && <Typography color="error" variant="body2">{salaryError}</Typography>}

        <FormControlLabel
            control={<Switch checked={hasFamilyExemption} onChange={(e) => setHasFamilyExemption(e.target.checked)} />}
            label={
                <Box>
                <span>{t('familyExemption')}</span> 
                <Tooltip onClick={(e) => e.stopPropagation()} title="This is the deduction for Jordan's mandatory social security program.">
                    <HelpOutline fontSize="small" style={{ margin: 4 }} />
                </Tooltip>
                </Box>
            }
        />

        <FormControlLabel
            control={<Switch checked={calculateSocialSecurity} onChange={(e) => setCalculateSocialSecurity(e.target.checked)} />}
            label={<span>{t('socialSecurityDeduction')} ({calculateSocialSecurity ? (isVoluntary ? '17.5%' : '7.5%') : '7.5%'})</span>}/>
        {calculateSocialSecurity && (
        <FormControlLabel
        style={{margin: '0 18px'}}
          control={<Checkbox checked={isVoluntary} onChange={(e) => setIsVoluntary(e.target.checked)} />}
          label={t('isVoluntarySubscription')}
        />
      )}
        
        <Button variant="contained"
         fullWidth 
         color="primary"
         style={{fontSize: '1.5rem'}}
         disabled={!!salaryError}
         onClick={handleSubmit}>
            {t('calculate')}
        </Button>

        </Stack>
        </Grid>

        <Grid item md={12} xl={12}>

        {result && (
        <CalculationResult monthlyTax={result.monthlyTax}
        netMonthlySalary={result.netMonthlySalary}
        socialSecurityDeduction={result.socialSecurityDeduction}
        yearlyTax={result.yearlyTax} />
        )}

        </Grid>

        <Grid item sm={12} width="100%">
        {savedCalculations?.length > 1 && (<div>
            <Typography variant="h6">{t('Previous Calculations')}</Typography>
            {savedCalculations.map((calc, index) => (
            <CalculationCard index={index} input={calc.input} result={calc.result}/>
            ))}
        </div>)}

        </Grid>
    </Grid>
  );
};

export default TaxForm;
