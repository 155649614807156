import { useEffect, useState } from 'react';
import './App.css';
import { Box, Container, CssBaseline, ThemeProvider, Typography, createTheme } from '@mui/material';
import { t } from 'i18next';
import Header from './components/Header';
import '@fontsource-variable/league-spartan';
import '@fontsource-variable/rubik';
import { useTranslation } from 'react-i18next';
import blue from '@mui/material/colors/blue';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import TaxCalculationGuide from './components/GuideBlogAr';

const getTheme = (language: string) => createTheme({
    palette: {
      primary: blue,
      secondary: {
        main: '#c0ca33',
      },
    },
  typography: {
    fontFamily: language === 'ar' ? `'Rubik Variable', serif` : `'League Spartan Variable', sans-serif`,
  },
  direction: language === 'ar' ? 'rtl' : 'ltr'
});

function App() {

  const { i18n } = useTranslation();
  const [theme, setTheme] = useState(getTheme(i18n.language));
  const [direction, setDirection] = useState('ltr');

  useEffect(() => {
    setTheme(getTheme(i18n.language));
    if (i18n.language === 'ar') {
      document.body.classList.add('ar');
      setDirection('rtl');
    } else {
      document.body.classList.remove('ar');
      setDirection('ltr');
    }
  }, [i18n.language]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
    <Box dir={direction}>
      <Header/>
      <Typography color="white" padding="8px 16px" bgcolor="#4898ff" variant="h5" component="h1" gutterBottom fontWeight="bold" textAlign="center">
          {t('Income Tax Calculator')}
      </Typography>
      <Container maxWidth="md" sx={{p: 2}}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tax-rules-blog" element={<TaxCalculationGuide />} />
          </Routes>
        </Router>
      </Container>
      <Footer />
    </Box>
    </ThemeProvider>
  );
}

export default App;