import React from 'react';
import { Typography, Box, Link, Container } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f8f8f8',
        padding: '16px 0',
        marginTop: 'auto',
        borderTop: '1px solid #ddd',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="body2" style={{direction: 'ltr'}} color="textSecondary" align="center">
          We don't collect any personal data, and all calculations are done <strong>locally in your browser</strong>.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          style={{direction: "ltr"}}
          alignItems="center"
          marginTop={1}
        >
          <Typography variant="body2" color="textSecondary" sx={{ marginRight: 1 }}>
            Connect with the Developer:
          </Typography>
          <Link
            href="https://www.linkedin.com/in/lqotom/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 2,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <LinkedInIcon  sx={{ marginRight: '4px' }} />
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
