import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const TaxCalculationGuide = () => {
  return (
    <div>
      <h1>دليل شامل لحساب ضريبة الدخل للأفراد في الأردن</h1>
      <p>
        ضريبة الدخل تعتبر جزءاً أساسياً من النظام المالي لأي دولة، وتساعد في تمويل الخدمات العامة مثل التعليم والصحة والبنية التحتية. في الأردن، تُفرض ضريبة الدخل على الأفراد والشركات وفقاً لقانون ضريبة الدخل رقم 34 لسنة 2014 وتعديلاته. في هذا المقال، سنستعرض كيفية حساب ضريبة الدخل للأفراد في الأردن ونوضح الإعفاءات المتاحة التي يمكن أن تقلل من مقدار الضريبة المفروضة.
      </p>

      <h2>ما هي ضريبة الدخل؟</h2>
      <p>
        ضريبة الدخل هي ضريبة تُفرض على الأفراد والشركات بناءً على دخلهم السنوي. تشمل الدخل من الوظائف، والأعمال التجارية، والاستثمارات، وأي مصادر دخل أخرى. تختلف النسب الضريبية حسب مقدار الدخل، مع تقديم إعفاءات للأفراد في بعض الحالات.
      </p>

      <h2>كيفية حساب ضريبة الدخل للأفراد</h2>
      <p>
        يُحسب الدخل الخاضع للضريبة بعد خصم الإعفاءات الشخصية والعائلية والمصاريف المسموح بها. وفقاً لقانون ضريبة الدخل، يتم حساب الضريبة وفقاً للنسب التالية:
      </p>
      <Table style={{direction: 'rtl'}}>
        <TableHead>
          <TableRow>
            <th>الفئة الضريبية</th>
            <th>نسبة الضريبة</th>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>أول 5000 دينار</TableCell>
            <TableCell>5%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>5000 دينار التالية</TableCell>
            <TableCell>10%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>5000 دينار التالية</TableCell>
            <TableCell>15%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>5000 دينار التالية</TableCell>
            <TableCell>20%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>من 20,000 حتى 1,000,000 دينار</TableCell>
            <TableCell>25%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ما يزيد عن 1,000,000 دينار</TableCell>
            <TableCell>30%</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>الإعفاءات المتاحة</h2>
      <p>يوفر قانون ضريبة الدخل إعفاءات للأفراد بناءً على حالتهم الاجتماعية والعائلية:</p>
      <Table style={{direction: 'rtl'}}>
        <TableHead>
          <TableRow>
            <TableCell>نوع الإعفاء</TableCell>
            <TableCell>قيمة الإعفاء السنوي</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>الإعفاء الشخصي</TableCell>
            <TableCell>9000 دينار</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>إعفاء المعالين</TableCell>
            <TableCell>9000 دينار</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>إعفاءات إضافية (العلاج، التعليم، الإيجار، فوائد قروض الإسكان والمرابحة)</TableCell>
            <TableCell>تصل إلى 5000 دينار</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>أمثلة على حساب ضريبة الدخل</h2>

      <h3>مثال 1:</h3>
      <p>
        إذا كان دخل شخص ما 15,000 دينار سنوياً، ويعول زوجة وطفلين:
        <br />
        - الإعفاءات: 9000 دينار شخصي + 9000 دينار للمعالين = 18,000 دينار.
        <br />
        - الدخل الخاضع للضريبة = 15,000 - 18,000 = 0 دينار (لا يوجد ضريبة مستحقة).
      </p>

      <h3>مثال 2:</h3>
      <p>
        إذا كان دخل شخص آخر 30,000 دينار سنوياً، ويعول زوجة وطفلين:
        <br />
        - الإعفاءات: 9000 دينار شخصي + 9000 دينار للمعالين = 18,000 دينار.
        <br />
        - الدخل الخاضع للضريبة = 30,000 - 18,000 = 12,000 دينار.
        <br />
        - الضريبة المستحقة:
        <br />
        5000 دينار × 5% = 250 دينار.
        <br />
        5000 دينار × 10% = 500 دينار.
        <br />
        2000 دينار × 15% = 300 دينار.
        <br />
        <strong>المجموع</strong> = 250 + 500 + 300 = 1050 دينار.
      </p>

      <h3>مثال 3:</h3>
      <p>
        إذا كان دخل شخص 35,000 دينار سنوياً، ويعول زوجة وطفلين، ويدفع 2000 دينار سنوياً لتعليم أبنائه:
        <br />
        - الإعفاءات: 9000 دينار شخصي + 9000 دينار للمعالين + 2000 دينار للتعليم = 20,000 دينار.
        <br />
        - الدخل الخاضع للضريبة = 35,000 - 20,000 = 15,000 دينار.
        <br />
        - الضريبة المستحقة:
        <br />
        5000 دينار × 5% = 250 دينار.
        <br />
        5000 دينار × 10% = 500 دينار.
        <br />
        5000 دينار × 15% = 750 دينار.
        <br />
        <strong>المجموع</strong> = 250 + 500 + 750 = 1500 دينار.
      </p>

      <h2>المواعيد النهائية لتقديم الإقرارات الضريبية</h2>
      <p>يجب على الأفراد تقديم إقراراتهم الضريبية قبل نهاية يوم 30 أبريل من كل عام. يمكن تقديم الإقرارات الضريبية عبر الموقع الرسمي لدائرة ضريبة الدخل والمبيعات.</p>

      <h2>روابط مفيدة</h2>
      <ul>
        <li><a href="https://www.isTableCell.gov.jo" target="_blank" rel="noopener noreferrer">الموقع الرسمي لدائرة ضريبة الدخل والمبيعات</a></li>
        <li><a href="https://eservices.isTableCell.gov.jo" target="_blank" rel="noopener noreferrer">بوابة تقديم الإقرارات الضريبية</a></li>
      </ul>
    </div>
  );
};

export default TaxCalculationGuide;
